<template>
  <div>
    <div class="add main-block">
      <div class="add__box main-block__head">
        <h1 class="add__box-title main-block__title">Информация получателя</h1>
      </div>
      <ul class="add__items">
        <li class="add__item flex">
          <p class="add__key">ФИО *</p>
          <input type="text" class="add__value" placeholder="ФИО" @focusout="onChange('to_fio', to_fio)" v-model="to_fio" required />
        </li>
        <li class="add__item flex">
          <p class="add__key">Контакты *</p>
          <input v-mask="'+7##########'" @focusout="onChange('to_phone', to_phone)" v-model="to_phone" class="add__value" placeholder="+7" required />
        </li>
        <li class="add__item flex">
          <p class="add__key">Город *</p>
          <b-form-select
              v-model="to"
              class="add__value"
              :options="listCities"
              text-field="name"
              value-field="name"
              @change="onChange('to', to)"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Адрес *</p>
          <input @focusout="onChange('address', address)" v-model="address" placeholder="Адрес" class="add__value" required @input="searchAddress()" />
          <ul v-if="searchShow" class="city-add__search-list">
            <li
                v-for="(item, index) in searchList"
                :key="index"
                class="city-add__search-item"
                @click="
                (searchItemClick = item.GeoObject.Point.pos.split(' ')),
                  (searchShow = false)
              "
            >
              {{ item.GeoObject.name }}, {{ item.GeoObject.description }}
            </li>
          </ul>
        </li>
        <MapBlock
            class="city-add__map"
            :search-click="searchItemClick"
            :get-coords="[lat, long]"
            @coords="coords = $event"
            @address="address = $event"
            :cityDeliveryZones="cityDeliveryZones"
        />
        <!-- <li class="add__item flex">
          <p class="add__key">Дата доставки *</p>
          <b-calendar
            v-show="dateDelivery"
            v-model="delivery"
            class="add__calendar"
            :hide-header="true"
            @context="onContext"
          />
          <input
            v-model="delivery"
            type="text"
            class="add__value"
            placeholder="Дата"
            required
            @click="dateDelivery = !dateDelivery"
            @input="dateDelivery = false"
          />
        </li>
        <li class="add__item flex">
          <p class="add__key">Время доставки</p>
          <input type="text" class="add__value add__value_aligin" placeholder="Время" />
        </li> -->
        <!-- <li class="add__item flex">
          <p class="add__key">Услуги грузчика при разгрузке *</p>
          <input type="text" class="add__value add__value_aligin" placeholder="" required />
        </li>
        <li class="add__item flex">
          <p class="add__key">Комментарий при доставке</p>
          <input type="text" class="add__value add__value_aligin" placeholder="Хорошо" />
        </li> -->
        <!-- <li class="add__item flex">
          <p class="add__key">Контерагент/организация *</p>
          <b-form-select
            v-model="selected"
            class="add__value main__select"
            :options="options"
            required
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="add__item flex">
          <p class="add__key">Филиал/Аптека *</p>
          <b-form-select
            v-model="selected"
            class="add__value main__select"
            :options="options2"
            required
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li> -->
      </ul>
    </div>
    <!-- <AddressComp class="add main-block mt-4" /> -->
    <div v-if="dateDelivery" class="main-close" @click="dateDelivery = false" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  components: {
    // AddressComp: () => ({
    //   component: import("../addressComp"),
    // }),
    MapBlock: () => ({
      component: import("@/components/map/map-city.vue"),
    }),
  },
  data() {
    return {
      selected: null,
      dateDelivery: false,
      delivery: null,
      options: [
        { value: null, text: "Контерагент" },
        { value: "a", text: "организация" },
      ],
      options2: [
        { value: null, text: "Филиал" },
        { value: "a", text: "Аптека" },
      ],
      to_fio: null,
      to_phone: '+7',
      address: null,
      to: 'Город',
      searchShow: false,
      searchList: null,
      searchItemClick: null,
      coords: null,
      long: 72.02503998567278,
      lat: 49.94813377060378,
      cityDeliveryZones: [],
    };
  },
  methods: {
    onContext() {
      this.dateDelivery = false;
    },
    async onChange(key, param) {
      this.$emit("onInputHandler", {
        key,
        param,
      });
    },
    async searchAddress() {
      if (this.address) {
        await axios
            .get(
                `https://geocode-maps.yandex.ru/1.x/?apikey=c2ed0336-3aa7-4167-9030-608c1ecbf17c&format=json&geocode=${this.address}&lang=ru_RU`
            )
            .then((response) => {
              this.searchList = response.data.response.GeoObjectCollection.featureMember;
            });
        if (this.searchList.length > 0) {
          this.searchShow = true;
        } else {
          this.searchShow = false;
        }
      }
    },
  },
  computed: {
    ...mapState(["citiesProducts"]),
    listCities() {
      if (this.citiesProducts) {
        return [{ id: null, name: "Город" }, ...this.citiesProducts];
      }
      return [{ id: null, name: "Город" }];
    },
  },
  watch: {
    coords(){
      this.lat = this.coords[0];
      this.long = this.coords[1];
      this.onChange('lat', this.lat);
      this.onChange('long', this.long);
    }
  }
};
</script>

<style scoped>
.add {
  min-height: auto;
  padding-bottom: 30px;
}

.add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}

.add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
.city-add__map {
  margin-top: 24px;
  height: 300px;
  width: 100%;
}
.city-add__search-list {
  width: 50%;
  max-height: 250px;
  padding: 10px;
  border-radius: 0px 0px 20px 20px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px -2px 20px rgba(16, 51, 115, 0.08);
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 2;
}
.city-add__search-item {
  padding: 10px 5px;
  color: #000;
  border-radius: 2px;
  cursor: pointer;
}
.city-add__search-item:not(:last-child) {
  border-bottom: 1px solid #b4b4b4;
}
.city-add__search-item:hover {
  background-color: rgba(203, 206, 225, 0.71);
}
</style>
